/*
  This file contains Angular Material style overrides for the components
  that could not have been styled via mixins or other conventional methods.

  Override Material styling only as a last resort
  as intruding into material styles can cause unexpected behaviours

  Consult these tomes for guidance:
    - https://indepth.dev/tutorials/angular/angular-material-theming-system-complete-guide
    - https://m2.material.io/design/layout/applying-density.html#usage
*/

@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  // $accent-palette: map.get($color-config, 'accent');
  // $warn-palette: map.get($color-config, 'warn');
  $foreground-palette: map.get($color-config, 'foreground');
  // $background-palette: map.get($color-config, 'background');


  /* ================ mat-select, mat-option, mat-checkbox, mat-pseudo-checkbox ================ */

  .mat-mdc-select-panel {
    color: mat.get-color-from-palette($foreground-palette, 'text') !important;
  }

  // TODO: Those two can possibly be styled properly, not sure :/
  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__checkmark,
  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__mixedmark {
    color: mat.get-color-from-palette($primary-palette, '300-contrast') !important;
    border-color: mat.get-color-from-palette($primary-palette, '300-contrast') !important;
  }

  .mat-mdc-select-panel .mdc-list-item__primary-text,
  .mat-mdc-option.mdc-list-item:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: mat.get-color-from-palette($foreground-palette, 'text') !important;
  }

  /* ================ mat-button ================ */

  // Overriding accent button text color, due to Material's persistent pick of black when palette hue is lower than 500
  // see https://m2.material.io/design/color/the-color-system.html#color-theme-creation
  .mat-mdc-unelevated-button.mat-primary:not(:disabled) {
    color: #fff;
  }
  .mat-mdc-outlined-button:not(:disabled) {
    --mdc-outlined-button-outline-color: mat.get-color-from-palette($primary-palette, 'default');
  }

  .mat-mdc-raised-button, .mat-mdc-outlined-button, .mat-mdc-flat-button {
    margin: 0;
  }

  /* ================ mat-chip, mat-chip-option ================*/

  .mdc-evolution-chip-set__chips > .mat-mdc-chip-option:not(.mat-mdc-chip-selected) {
    border-color: mat.get-color-from-palette($foreground-palette, 'divider');
  }

  .mat-mdc-chip-option:not(.mdc-evolution-chip--disabled):not(.mat-mdc-chip-selected) .mdc-evolution-chip__text-label {
    color: mat.get-color-from-palette($foreground-palette, 'text') !important;
  }

  // TODO: Move this to a dedicated mat-table wrapper component when implemented (shared/table/ - but with some refactoring)
  /* ================ mat-table ================ */

  .mat-mdc-row, .mat-mdc-footer-row {
    &.selected, &.selected:hover {
      background-color: rgba(mat.get-color-from-palette($primary-palette, 'default'), 0.05);
    }
  }
}
