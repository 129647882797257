@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');
  $foreground-palette: map.get($color-config, 'foreground');
  $background-palette: map.get($color-config, 'background');

  html, body {
    color: mat.get-color-from-palette($foreground-palette, 'text'); // (light) #707070, (dark) ...
  }

  h1, .mat-headline-1 {
    color: mat.get-color-from-palette($primary-palette, 'default'); // (light) #67bfaf, (dark) ...
    line-height: 1.2;
  }
  h2, .mat-headline-2 {
    color: mat.get-color-from-palette($primary-palette, 'default'); // (light) #67bfaf, (dark) ...
  }
  h3, h4, .mat-headline-3, .mat-headline-4 {
    color: mat.get-color-from-palette($accent-palette, 'default'); // (light) #143987, (dark) ...
  }

  .th-color-primary {
    color: mat.get-color-from-palette($primary-palette, 'default'); // (light) #67bfaf, (dark) ...
  }
  .th-background-primary {
    background-color: mat.get-color-from-palette($primary-palette, 'default'); // (light) #67bfaf, (dark) ...
  }

  .th-color-accent {
    color: mat.get-color-from-palette($accent-palette, 'default'); // (light) #143987, (dark) ...
  }
  .th-background-accent {
    background-color: mat.get-color-from-palette($accent-palette, 'default'); // (light) #143987, (dark) ...
  }

  .th-color-warn {
    color: mat.get-color-from-palette($warn-palette, 'default'); // (light) #de4d4d, (dark) ...
  }
  .th-background-warn {
    background-color: mat.get-color-from-palette($warn-palette, 'default'); // (light) #de4d4d, (dark) ...
  }

  .th-border-color {
    border-color: mat.get-color-from-palette($foreground-palette, 'divider');
  }

  a {
    color: inherit;

    &:hover {
      filter: brightness(80%);
    }
  }

  .caption {
    font-weight: 100;
    color: mat.get-color-from-palette($foreground-palette, 'caption-text');
  }

  .gm-style .gm-style-iw-c {
    & > button {
      & span {
        background-color: mat.get-color-from-palette($primary-palette, 'main-contrast') !important;
      }
    }
  }
}
