@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $background-palette: map.get($color-config, 'background');
  $foreground-palette: map.get($color-config, 'foreground');

  .vehicle-list .vehicle-filters .vehicle-state-filter:not(.active) {
    color: mat.get-color-from-palette($foreground-palette, 'secondary-text') !important;
    border-color: mat.get-color-from-palette($foreground-palette, 'secondary-text') !important;
  }

  .state-icon-container.alert-state .state-alerts .state-alert {
    border-color: mat.get-color-from-palette($foreground-palette, 'divider') !important;
  }
}
