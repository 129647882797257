@use '@angular/material' as mat;

@import './values.scss';
@import './typography.scss';
@import './material-overrides.scss';

html, body { height: 100%; font-size: $font-size-base }
body {
  margin: 0;
  font-family: $std-font-family;
}
* { box-sizing: border-box; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
.mat-typography {
  h1, h2, h3, h4, h5, h6,
  *[class^='mat-headline-'] {
    margin: 0;
  }
}

img, svg {
  vertical-align: baseline;
}

// overriding default density behaviour for mat-form-fields
@include mat.form-field-density(-2);
.mat-mdc-form-field {
  @include mat.icon-button-density(-4);

  .mat-mdc-form-field-icon-suffix {
    padding-right: 6px;
  }

  &.narrow {
    @include mat.form-field-density(-5);
  }

  &.large {
    @include mat.form-field-density(0);
    font-size: 1rem;
  }
}

.zeva-page {
  position: relative;
  padding: 0 4rem 4rem;
  margin-top: 1rem;

  // page spans the entire window's height and width (except header space)
  &.takeover {
    width: 100%;
    padding: 0;
    margin-top: 0;
  }
}

/* Dialogs */
// .zeva-dialog .mat-mdc-dialog-container {
//   padding: 0;

//   .dialog-header {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//   }

//   .mat-mdc-dialog-title, .mat-mdc-dialog-content {
//     margin: 0;
//   }
// }

/* Page Headers */
.page-header {
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;

  .page-header-button {
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    .mat-button-wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    mat-icon {
      font-size: 1rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  h1 {
    font-size: 1.4rem;
  }
}

.zeva-fixed-header-left {
  position: fixed;
  display: block;
  z-index: 9999;

  top: 0;
  left: 0;
  height: 5rem; // height of the header
  width: 11.5rem;

  .logo-container {
    position: relative;
    max-height: 100%;
    top: 25%;
    width: 75%;
    left: 12.5%;

    & > img {
      width: 100%;
      max-height: 100%;
    }
  }
}

.zeva-main-container {
  height: 100%;

  .mat-drawer-inner-container {
    padding-top: 5rem;
  }

  .sidenav {
    width: 280px;

    // padding: 1rem 0 1rem 1rem;
    // background: #fafafa;
    border-color: transparent;

    .mdc-button.menu-item {
      display: block;
      width: 100%;
      text-align: left;
    }
  }
}


/* Map */
.zeva-map {
  .map-info-window {
    background-color: rgba(255, 255, 255,  0.8);
    padding: 1rem;
    cursor: default;
    border-radius: $border-radius;

    p {
      margin: 0;
    }
  }

  // Disable undo button on polygon/circle edition
  img[src*="https://maps.gstatic.com/mapfiles/undo_poly.png"] {
    display: none;
  }
}

// TODO: Fix this in the ngx-ta-maps lib and remove the code below
ngx-ta-maps {
  .loading-container {
    top: 0;

    .mat-mdc-progress-bar {
      bottom: unset !important;
    }
  }
}

// Custom mat-select panel
.custom-select {
  .mat-mdc-optgroup .mat-mdc-option {
    padding-left: 2rem;
  }

  .mat-mdc-optgroup-label span {
    width: 100%;
  }

  .all-option {
    display: block;
    position: relative;
    padding: 0.4rem;
    cursor: pointer;

    & > * {
      vertical-align: middle;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    .prompt {
      position: relative;
      display: inline;
      margin-left: 0.3rem;

      font-weight: 500;
    }
  }

  .mat-mdc-optgroup-label {
    padding: 0;

    mat-label {
      position: relative;
      display: inline-block;
      width: 100%;
      padding: 0 16px;
    }

    .all-option {
      padding-left: 1.3rem;
    }
  }
}

// Handle buttons appropriately, with respect to line-height
.mat-mdc-raised-button, .mat-mdc-outlined-button, .mat-mdc-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}

.mat-ripple-element {
  display: none;
}

.pwd-visibility-ctl {
  cursor: pointer;
}

.mat-mdc-table.selectable {
  .mat-mdc-row {
    cursor: pointer;
  }
}

/** Styles for the generic table legend for a chart (like the ones on Analytics and Insights) */
.zeva-generic-table-legend {
  display: flex;
  align-items: center;

  .mat-mdc-table {
    height: fit-content;
    max-height: 100%;
    overflow-y: auto;

    .mat-mdc-row {
      cursor: pointer;
      overflow: hidden;

      border-radius: $border-radius;

      &:not(.active) {
        opacity: 0.7;
      }
    }
    .mat-mdc-cell {
      border: none;
    }

    .mat-mdc-cell:first-of-type, .mat-mdc-header-cell:first-of-type {
      padding-left: 0.25rem;
    }
    .mat-mdc-cell:last-of-type, .mat-mdc-header-cell:last-of-type {
      padding-right: 0.25rem;
    }

    .mat-column-identity {
      min-width: 40%;
      align-items: center;

      h2 {
        font-size: 1.9rem;
        font-weight: 700;
      }

      .legend-circle {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
        border-radius: 100px;
      }

      &:not(.mat-mdc-header-cell) > span {
        width: calc(100% - 1.5rem);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &.mat-mdc-header-cell {
        justify-content: space-between;
        align-items: flex-end;
        padding-right: 0;
        padding-bottom: 1rem;
      }
    }

    .mat-column-share {
      justify-content: center;
    }

    .mat-column-total {
      min-width: 7.5rem;
      justify-content: flex-end;
      text-align: right;
    }
  }
}

.zeva-ranking-table {
  .mat-mdc-cell {
    min-width: fit-content;
  }

  .mat-mdc-cell:first-of-type {
    padding-left: 0;
  }
  .mat-mdc-cell:last-of-type {
    padding-right: 0;
  }

  .mat-column-vehicle {
    min-width: 10rem; // 10rem to consistently fit vehicle's VIN number

    .vehicle-cell {
      position: relative;
      width: 100%;

      .name, .vin {
        margin: 0;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .vin {
        font-size: 0.8rem;
        font-weight: 300;
      }
    }
  }

  .mat-column-growth {
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    & > p {
      font-size: 0.7rem;
      margin: 0.2rem 0 0;
      line-height: 1;
    }
  }
}

chart-support-outlet {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
}
