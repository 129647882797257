@use 'sass:map';
@use '@angular/material' as mat;

// TODO: Move this to global theme.scss, since this is now shared
@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $background-palette: map.get($color-config, 'background');
  $foreground-palette: map.get($color-config, 'foreground');

  .zeva-main-container .mat-drawer-inner-container {
    background: mat.get-color-from-palette($background-palette, 'app-bar') !important;
    color: mat.get-color-from-palette($foreground-palette, 'text') !important;
  }
}
