@use '@angular/material' as mat;

// Fonts & Typography
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500&display=swap');

@import './values.scss';

$font-family-base: $std-font-family;
$font-size-base: 16px;
$line-height-base: 1;

$typography-config: mat.define-typography-config(
  $font-family: $font-family-base,
  $headline-1: mat.define-typography-level(1.953rem, 1.953rem, 400, $letter-spacing: -0.0134em),
  $headline-2: mat.define-typography-level(1.72rem, 1.72rem, 400, $letter-spacing: -0.0089em),
  $headline-3: mat.define-typography-level(1.5rem, 1.5rem, 400, $letter-spacing: 0.0000em),
  $headline-4: mat.define-typography-level(1.36rem, 1.36rem, 400, $letter-spacing: 0.0074em),
  $headline-5: mat.define-typography-level(1.25rem, 1.25rem, 400, $letter-spacing: 0.0000em),
  $headline-6: mat.define-typography-level(1rem, 1rem, 400, $letter-spacing: 0.0075em),
  $subtitle-1: mat.define-typography-level(16px, 24px, 300, $letter-spacing: 0.0094em),
  $subtitle-2: mat.define-typography-level(15px, 20px, 300, $letter-spacing: 0.0067em),
  $body-1: mat.define-typography-level(16px, 24px, 400, $letter-spacing: 0.0179em),
  $body-2: mat.define-typography-level(16px, 20px, 400, $letter-spacing: 0.0179em),
  $caption: mat.define-typography-level(14px, 20px, 400, $letter-spacing: 0.0333em),
  $button: mat.define-typography-level(16px, 16px, 500, $letter-spacing: 0.0893em),
  /* $input: mat.define-typography-level(inherit, 1.125, 400, $letter-spacing: 1.5px) */
);
