@use '@angular/material' as mat;
@use 'sass:map';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

@use './misc-theme.scss' as misc;
@use './material-overrides-theme.scss' as material-override;
@use '../bootstrap.scss' as bootstrap;
@use '../../app/core/home/home-theme.component.scss' as home;
@use '../../app/core/sidenav/sidenav-theme.component.scss' as sidenav;
@use '../../app/auth/auth-theme.component.scss' as auth;
@use '../../app/core/card-grid/components/card/card-theme.component.scss' as card;
@use '../../app/core/header/header-theme.component.scss' as header;
@use '../../app/shared/table/table-theme.component.scss' as table;
@use '../../app/shared/progress-bar/progress-bar-theme.component.scss' as progress-bar;
@use '../../app/shared/mat-extended/datetime-picker/time-picker/time-picker-theme.component.scss' as time-picker;
@use '../../app/monitoring/pages/real-time-monitoring/components/rtm-map-query-list/rtm-map-query-list-theme.component.scss' as rtm-query-list;

@include mat.core();

@import '../values.scss';

/* Importing Bootstrap SCSS files. */
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
// This import will provide set typography settings and override Bootstrap's Typography
@import '../typography.scss';

@import 'bootstrap/scss/bootstrap';

/* Theme Declaration */

// Theme Palettes
@import './theme-light.scss';
@import './theme-dark.scss';

// Theme Definitions
// Light
$theme-light: (
  color: (
    primary: map.get($palette-light, 'primary'),
    accent: map.get($palette-light, 'accent'),
    warn: map.get($palette-light, 'warn'),
    foreground: $mat-foreground-light,
    background: $mat-background-light,
  ),
  density: 0,

  is-dark: false,
);
// Dark
$theme-dark: (
  color: (
    primary: map.get($palette-dark, 'primary'),
    accent: map.get($palette-dark, 'accent'),
    warn: map.get($palette-dark, 'warn'),
    foreground: $mat-foreground-dark,
    background: $mat-background-dark,
  ),
  density: 0,

  is-dark: true,
);

/* Theme Init */

@include mat.all-component-typographies($typography-config);

.theme--light {
  @include mat.all-component-themes($theme-light);

  @include bootstrap.theme($theme-light);
  @include material-override.theme($theme-light);

  @include misc.theme($theme-light);
  @include home.theme($theme-light);
  @include sidenav.theme($theme-light);
  @include auth.theme($theme-light);
  @include header.theme($theme-light);
  @include card.theme($theme-light);
  @include table.theme($theme-light);
  @include progress-bar.theme($theme-light);
  @include time-picker.theme($theme-light);
  @include rtm-query-list.theme($theme-light);
}

.theme--dark {
  @include mat.all-component-colors($theme-dark);

  @include bootstrap.theme($theme-dark);
  @include material-override.theme($theme-dark);

  @include misc.theme($theme-dark);
  @include home.theme($theme-dark);
  @include sidenav.theme($theme-dark);
  @include auth.theme($theme-dark);
  @include header.theme($theme-dark);
  @include card.theme($theme-dark);
  @include table.theme($theme-dark);
  @include progress-bar.theme($theme-dark);
  @include time-picker.theme($theme-dark);
  @include rtm-query-list.theme($theme-dark);
}
