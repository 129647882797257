@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $foreground-palette: map.get($color-config, 'foreground');
  $background-palette: map.get($color-config, 'background');

  .menu-holder {
    .menu-item {
      background-color: inherit;

      .link {
        background-color: mat.get-color-from-palette($background-palette, 'app-bar') !important;
        color: mat.get-color-from-palette($foreground-palette, 'text') !important;

        .mat-expansion-indicator:after {
          color: mat.get-color-from-palette($foreground-palette, 'text') !important;
        }

        &:hover {
          background-color: mat.get-color-from-palette($background-palette, 'disabled-button-toggle') !important;
        }
      }

      &.active .link {
        // background-color: mat.get-color-from-palette($primary-palette, 'lighter') !important;
        color: mat.get-color-from-palette($primary-palette, 'main-contrast') !important;

        .mat-expansion-indicator:after {
          color: mat.get-color-from-palette($primary-palette, 'main-contrast') !important;
        }

        box-shadow: inset 12px 0 0 -6px mat.get-color-from-palette($primary-palette, 'default');
      }
    }
  }
}
