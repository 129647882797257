@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config:       mat.get-color-config($theme);
  $accent-palette:     map.get($color-config, 'accent');
  $warn-palette:       map.get($color-config, 'warn');
  $foreground-palette: map.get($color-config, 'foreground');
  $background-palette: map.get($color-config, 'background');

  zeva-card, .zeva-card {
    background-color: mat.get-color-from-palette($background-palette, 'card');
    color: mat.get-color-from-palette($foreground-palette, 'text');

    & .active-indicator {
      background-color: mat.get-color-from-palette($accent-palette, 'lighter');
    }

    .card-body {
      background-color: inherit;
    }

    &.color-warning {
      border-color: mat.get-color-from-palette($warn-palette, 'default');
      color: mat.get-color-from-palette($warn-palette, 'default');
    }

    &.tile {
      box-shadow: 0 0 1rem 1rem mat.get-color-from-palette($background-palette, 'card-shadow');
    }

    &.outlined {
      border-top-color: mat.get-color-from-palette($foreground-palette, 'divider');
      border-right-color: mat.get-color-from-palette($foreground-palette, 'divider');
      border-bottom-color: mat.get-color-from-palette($foreground-palette, 'divider');
      border-left-color: mat.get-color-from-palette($foreground-palette, 'divider');
    }
  }
}
