:root {
  /* Material Button overrides */
  --mdc-outlined-button-outline-width: 2px;
  --mdc-typography-button-font-size: 14px;
  --mdc-chip-elevated-container-color: transparent;
}

.mdc-evolution-chip-set__chips {
  display: flex;
  justify-content: flex-start;
  width: calc(100% + 8px);
}

.mdc-evolution-chip, .mdc-evolution-chip__cell, .mdc-evolution-chip__action {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.mdc-evolution-chip-set__chips > .mat-mdc-chip-option {
  min-width: 5rem;
  text-align: center;
  display: flex;
  justify-content: center;

  &:not(.mat-mdc-chip-selected) {
    background: none !important;
    border: solid 1px;
  }

  & .mat-mdc-chip-graphic {
    width: 0 !important;
    opacity: 0 !important;
  }
}
